import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Image,
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation();
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/w-cc/_functions", // Replace with your API base URL
      applicationID: "da523687-ea30-417f-b52b-4b4eab4c685e", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, (value: boolean) => {
      setIsUpgraded(value);
      setIsUpgradeBannerOpen(!value);
    });
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loading")} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeRedirectionUrl = `https://www.wix.com/editor/${instanceData?.site?.siteId}`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <>
              <Notification
                theme="premium"
                show={isUpgradeBannerOpen}
                type={"sticky"}
              >
                <Notification.TextLabel ellipsis={true}>
                  {t("upgrade-text")}
                </Notification.TextLabel>
                <Notification.ActionButton
                  onClick={() => {
                    window.open(upgradeRedirectionUrl, "_blank");
                  }}
                >
                  {t("upgrade-button")}
                </Notification.ActionButton>
                <Notification.CloseButton
                  onClick={() => setIsUpgradeBannerOpen(false)}
                />
              </Notification>
              <Box height="840px" verticalAlign="middle">
                <MarketingPageLayoutContent
                  title={t("currency-converter-title")}
                  content={t("currency-converter-content")}
                  actions={
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.Edit />}
                        as="a"
                        href={upgradeRedirectionUrl}
                        target="_blank"
                      >
                        {t("currency-converter-edit-button")}
                      </Button>
                      <Button
                        suffixIcon={<Icons.PremiumFilled />}
                        skin={isUpgraded ? "premium-light" : "premium"}
                        as="a"
                        href={basePlatform.getUpgradeUrl()}
                        target="_blank"
                      >
                        {isUpgraded
                          ? t("currency-converter-manage-button")
                          : t("currency-converter-upgrade-button")}
                      </Button>
                    </Box>
                  }
                />
              </Box>
            </>
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
